import StoreResponse from './Module/Store/StoreResponse';
import SignupRequest from './Module/Auth/SignupRequest';
import BundleResponse from './Module/Bundle/BundleResponse';
import LoginTokenResponse from './Module/Auth/LoginTokenResponse';
import MyEsimResponse from './Module/Esim/MyEsimResponse';
import PaymentResponse from './Module/Payment/PaymentResponse';
import MyUserResponse from './Module/Profile/MyUserResponse';
import SessionRequest from './Module/Session/SessionRequest';
import SessionResponse from './Module/Session/SessionResponse';
import BooleanResponse from './Module/Common/BooleanResponse';
import ActionRequest from './Module/Action/ActionRequest';
import ActiveCountriesResponse from './Module/Geo/ActiveCountriesResponse';
import CountriesActiveResponse from './Module/Geo/CountriesActiveResponse';
import MarketingAnalyticsEventRequest from './Module/Marketing/Analytics/MarketingAnalyticsEventRequest';
import PriceResponse from './Module/Price/PriceResponse';
import GooglePayGatewayRequest from './Module/Payment/Gateway/GooglePayGatewayRequest';
import GooglePayGatewayResponse from './Module/Payment/Gateway/GooglePayGatewayResponse';
import SplitRequest from './Module/SplitTesting/SplitRequest';
import LoginCodeRequest from './Module/Auth/LoginCodeRequest';
import LoginSendCodeRequest from './Module/Auth/LoginSendCodeRequest';
import SignupSendCodeRequest from './Module/Auth/SignupSendCodeRequest';
import MyPaymentResponse from './Module/Payment/MyPaymentResponse';
import IsRegisteredRequest from './Module/Auth/IsRegisteredRequest';
import SplitResponse from './Module/SplitTesting/SplitResponse';
import ApplyDiscountRequest from './Module/Payment/Gateway/ApplyDiscountRequest';
import CreateUserFlagRequest from './Module/User/CreateUserFlagRequest';
import MySubscriptionResponse from './Module/Subscription/MySubscriptionResponse';
import SubProductResponse from './Module/Subscription/SubProductResponse';
import SubCancelFeedbackRequest from './Module/Subscription/SubCancelFeedbackRequest';
import ReviewsResponse from './Module/Review/ReviewResponse';
import LoginViaGoogleRequest from './Module/Auth/LoginViaGoogleRequest';
import LoginViaAppleRequest from './Module/Auth/LoginViaAppleRequest';
import CountryDetailsResponse from './Module/Geo/CountryDetailsResponse';
import UserRateRequest from './Module/User/UserRateRequest';
import SubEsimOperatorResponse from './Module/Subscription/SubEsimOperatorResponse';
import SubApplyDiscountRequest from '@shared_backend/Module/Subscription/SubApplyDiscountRequest';

export const API_ROUTE_AUTH_SIGNUP = '/auth/signup';
export const API_ROUTE_AUTH_SIGNUP_SEND_EMAIL_CODE =
  '/auth/signup/send_email_code';
export const API_ROUTE_AUTH_SIGNUP_RESEND_EMAIL_CODE =
  '/auth/signup/resend_email_code';
export const API_ROUTE_AUTH_IS_REGISTERED = '/auth/is_registered';
export const API_ROUTE_AUTH_LOGIN_EMAIL_TOKEN = '/auth/login/email_token';
export const API_ROUTE_AUTH_LOGIN_EMAIL_CODE = '/auth/login/email_code';
export const API_ROUTE_AUTH_SEND_EMAIL_CODE = '/auth/login/send_email_code';
export const API_ROUTE_AUTH_RESEND_EMAIL_CODE = '/auth/login/resend_email_code';
export const API_ROUTE_AUTH_CHECK_TOKEN = '/auth/check_token';
export const API_ROUTE_AUTH_LOGIN_GOOGLE = '/auth/login/google';
export const API_ROUTE_AUTH_LOGIN_APPLE = '/auth/login/apple';
export const API_ROUTE_MY_USER = '/my/user';
export const API_ROUTE_MY_ESIMS = '/my/esims';
export const API_ROUTE_MY_PAYMENTS = '/my/payments';
export const API_ROUTE_MY_PAYMENT_SESSIONS = '/my/payment_sessions';
export const API_ROUTE_POST_MARKETING_ANALYTICS = '/marketing/analytics';
export const API_ROUTE_CREATE_PAYMENT = '/payment/:bundleCodeAndPriceId';
export const API_ROUTE_CREATE_PAYMENT_GOOGLE_PAY_SOLIDGATE =
  '/payment/google_pay_/solidgate';
export const API_ROUTE_PAYMENT_APPLY_DISCOUNT = '/payment/discount/apply';
export const API_ROUTE_GET_PAYMENT_STATUS = '/payment/:paymentSessionId';
export const API_ROUTE_SELF_TEST = '/self_test';
// TODO: Redundant API. Needs to be removed after mobile app update enforcement period
export const API_ROUTE_GEO_ACTIVE_COUNTRIES = '/geo/active_countries';
export const API_ROUTE_GEO_COUNTRIES_ACTIVE = '/geo/countries_active';
export const API_ROUTE_GEO_COUNTRY_DETAILS = '/geo/country/:countryCode';
export const API_ROUTE_GEO_COUNTRY_SEARCH = '/geo/country_search';
export const API_ROUTE_COUNTRY_STORE = '/store/:country';
export const API_ROUTE_BUNDLE = '/bundle/:bundleShortCode';
export const API_ROUTE_BUNDLE_BY_CODE = '/bundle_code/:bundleFullCode';
export const API_ROUTE_PRICE = '/price/:priceId';
export const API_ROUTE_SESSION = '/session';
export const API_ROUTE_QRCODE = '/qrcode/:base64data';
export const API_ROUTE_POST_ACTION = '/action';
export const API_ROUTE_SPLIT_MAY_ALLOCATE = '/split/may_allocate';
export const API_ROUTE_COMPATIBLE_DEVICES = '/compatible_devices';
export const API_ROUTE_MRK_EMAILS_UNSUBSCRIBE = '/user/mrk_emails/unsubscribe';
export const API_ROUTE_USER_FLAG_CREATE = '/user/flags';
export const API_ROUTE_USER_RATE = '/user/rate';
export const API_ROUTE_USER_DELETE_ACCOUNT = '/user/delete-account';
export const API_ROUTE_REVIEWS_PER_COUNTRY = '/review/:countryCode';
// ------ Subscriptions ---------------
export const API_ROUTE_CREATE_SUB_PAYMENT = '/subscription/payment/:productId';
export const API_ROUTE_SUB_PAYMENT_APPLY_DISCOUNT =
  '/subscription/payment/discount/apply';
export const API_ROUTE_GET_SUB_PAYMENT_STATUS =
  '/subscription/payment/:paymentSessionId';
export const API_ROUTE_SUB_CANCEL = '/subscription/cancel/:subId';
export const API_ROUTE_MY_SUBS = '/my/subscriptions';
export const API_ROUTE_SUB_PRODUCTS = '/subscription/products';
export const API_ROUTE_SUB_CANCEL_FEEDBACK =
  '/subscription/:subId/cancel-feedback';
export const API_ROUTE_SUB_ESIM_OPERATORS = '/subscription/esim_operators';
// --------- Webhooks -----------------
export const API_ROUTE_WEBHOOK_SOLIDGATE = '/webhook/solidgate';
export const API_ROUTE_WEBHOOK_ESIM_ACCESS = '/webhook/provider/esim_access';
export const API_ROUTE_WEBHOOK_TELCO_VISION = '/webhook/provider/telco_vision';
export const API_ROUTE_WEBHOOK_SENDGRID = '/webhook/email/sendgrid';
export const API_ROUTE_WEBHOOK_CUSTOMERIO = '/webhook/email/customerio';
// -------------------------------------

export const routeRequests = {
  [API_ROUTE_AUTH_SIGNUP]: SignupRequest,
  [API_ROUTE_AUTH_SIGNUP_SEND_EMAIL_CODE]: SignupSendCodeRequest,
  [API_ROUTE_AUTH_SIGNUP_RESEND_EMAIL_CODE]: SignupSendCodeRequest,
  [API_ROUTE_AUTH_IS_REGISTERED]: IsRegisteredRequest,
  [API_ROUTE_SESSION]: SessionRequest,
  [API_ROUTE_POST_ACTION]: ActionRequest,
  [API_ROUTE_POST_MARKETING_ANALYTICS]: MarketingAnalyticsEventRequest,
  [API_ROUTE_CREATE_PAYMENT_GOOGLE_PAY_SOLIDGATE]: GooglePayGatewayRequest,
  [API_ROUTE_SPLIT_MAY_ALLOCATE]: SplitRequest,
  [API_ROUTE_AUTH_LOGIN_EMAIL_CODE]: LoginCodeRequest,
  [API_ROUTE_AUTH_SEND_EMAIL_CODE]: LoginSendCodeRequest,
  [API_ROUTE_AUTH_LOGIN_GOOGLE]: LoginViaGoogleRequest,
  [API_ROUTE_AUTH_LOGIN_APPLE]: LoginViaAppleRequest,
  [API_ROUTE_AUTH_RESEND_EMAIL_CODE]: LoginSendCodeRequest,
  [API_ROUTE_PAYMENT_APPLY_DISCOUNT]: ApplyDiscountRequest,
  [API_ROUTE_SUB_PAYMENT_APPLY_DISCOUNT]: SubApplyDiscountRequest,
  [API_ROUTE_USER_FLAG_CREATE]: CreateUserFlagRequest,
  [API_ROUTE_USER_RATE]: UserRateRequest,
  [API_ROUTE_SUB_CANCEL_FEEDBACK]: SubCancelFeedbackRequest,
};

export const routeResponses = {
  [API_ROUTE_SESSION]: SessionResponse,
  [API_ROUTE_COUNTRY_STORE]: StoreResponse,
  [API_ROUTE_BUNDLE]: BundleResponse,
  [API_ROUTE_BUNDLE_BY_CODE]: BundleResponse,
  [API_ROUTE_PRICE]: PriceResponse,
  [API_ROUTE_AUTH_LOGIN_EMAIL_TOKEN]: LoginTokenResponse,
  [API_ROUTE_AUTH_LOGIN_GOOGLE]: LoginTokenResponse,
  [API_ROUTE_AUTH_LOGIN_APPLE]: LoginTokenResponse,
  [API_ROUTE_AUTH_CHECK_TOKEN]: BooleanResponse,
  [API_ROUTE_MY_ESIMS]: MyEsimResponse,
  [API_ROUTE_MY_PAYMENTS]: MyPaymentResponse,
  [API_ROUTE_CREATE_PAYMENT]: PaymentResponse,
  [API_ROUTE_GET_PAYMENT_STATUS]: PaymentResponse,
  [API_ROUTE_MY_USER]: MyUserResponse,
  [API_ROUTE_GEO_ACTIVE_COUNTRIES]: ActiveCountriesResponse,
  [API_ROUTE_GEO_COUNTRIES_ACTIVE]: CountriesActiveResponse,
  [API_ROUTE_CREATE_PAYMENT_GOOGLE_PAY_SOLIDGATE]: GooglePayGatewayResponse,
  [API_ROUTE_AUTH_SIGNUP_SEND_EMAIL_CODE]: BooleanResponse,
  [API_ROUTE_AUTH_SIGNUP_RESEND_EMAIL_CODE]: BooleanResponse,
  [API_ROUTE_SPLIT_MAY_ALLOCATE]: SplitResponse,
  [API_ROUTE_MRK_EMAILS_UNSUBSCRIBE]: BooleanResponse,
  [API_ROUTE_PAYMENT_APPLY_DISCOUNT]: PaymentResponse,
  [API_ROUTE_SUB_PAYMENT_APPLY_DISCOUNT]: PaymentResponse,
  [API_ROUTE_USER_FLAG_CREATE]: BooleanResponse,
  [API_ROUTE_USER_RATE]: BooleanResponse,
  [API_ROUTE_CREATE_SUB_PAYMENT]: PaymentResponse,
  [API_ROUTE_GET_SUB_PAYMENT_STATUS]: PaymentResponse,
  [API_ROUTE_SUB_CANCEL]: BooleanResponse,
  [API_ROUTE_MY_SUBS]: MySubscriptionResponse,
  [API_ROUTE_SUB_PRODUCTS]: SubProductResponse,
  [API_ROUTE_SUB_CANCEL_FEEDBACK]: BooleanResponse,
  [API_ROUTE_REVIEWS_PER_COUNTRY]: ReviewsResponse,
  [API_ROUTE_GEO_COUNTRY_DETAILS]: CountryDetailsResponse,
  [API_ROUTE_SUB_ESIM_OPERATORS]: SubEsimOperatorResponse,
  [API_ROUTE_USER_DELETE_ACCOUNT]: BooleanResponse,
};
