import styled from 'styled-components';
import {
  WEB_ROUTE_AUTH,
  WEB_ROUTE_MY_PROFILE,
} from '@shared_frontend/Common/WebRoutes';
import { LinkToRoute } from '@front_common/Router/Router';
import Button from '@front_common/Components/Button/Button';
import ProfileIcon from '../Icons/ProfileIcon';

type IProps = {
  authenticated: boolean;
};

export default function SignInProfileButton({ authenticated }: IProps) {
  return authenticated ? (
    <LinkToRoute href={WEB_ROUTE_MY_PROFILE} style={{ textDecoration: 'none' }}>
      <Button
        sizeParam="S"
        type="Primary"
        leftIcon={
          <ProfileIconWrapper>
            <ProfileIcon />
          </ProfileIconWrapper>
        }
      >
        Profile
      </Button>
    </LinkToRoute>
  ) : (
    <LinkToRoute href={`${WEB_ROUTE_AUTH}/`} style={{ textDecoration: 'none' }}>
      <CustomButton sizeParam="S" type="Primary">
        Sign In
      </CustomButton>
    </LinkToRoute>
  );
}

let ProfileIconWrapper = styled.div`
  height: 20px;
  width: 20px;

  svg path {
    fill: var(--iconsPrimary);
  }
`;

let CustomButton = styled(Button)`
  width: 79px;

  @media (min-width: 700px) {
    width: 108px;
  }
`;
